<template>
  <div class="card">
    <div class="card-header pb-75" style="padding-right: 0">
      <div class="w-100 row justify-content-between align-items-center">
        <div
          class="d-flex flex-column align-items-center justify-content-start col-sm-12"
        >
          <h3 class="mb-25 font-weight-bolder">Edit Plan</h3>
          <p class="mb-50">
            Make changes in the necessary steps and click on the finish button
            to Update.
          </p>
        </div>
      </div>
    </div>
    <div class="card-body"><edit-plan-form /></div>
  </div>
</template>

<script>
import EditPlanForm from "./components/EditPlanForm.vue";
export default {
  components: { EditPlanForm },
};
</script>

<style>
</style>